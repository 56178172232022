<template>
	<v-container>
		<validation-observer ref="observer" v-slot="{ invalid }">
			<form @submit.prevent="authenticate">
				<v-card elevation="24">
					<v-card-title>{{ $t("login.title") }}</v-card-title>
					<v-card-text>
						<validation-provider v-slot="{ errors }" name="username" rules="required|email">
							<v-text-field	v-model="username" :error-messages="errors" :label="$t('login.username')"></v-text-field>
						</validation-provider>
						<validation-provider v-slot="{ errors }" name="password" rules="required">
							<v-text-field	v-model="password" :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :error-messages="errors" :label="$t('login.password')" @click:append="show1 = !show1"></v-text-field>
						</validation-provider>
						<v-alert v-if="show_login_error"  dense type="error" text>{{ $t(login_message) }}</v-alert>
						<v-btn block :disabled="invalid" type="submit">{{	$t("login.btn_login") }}</v-btn>
						<br>
					</v-card-text>
					<v-row align="center" justify="space-around">
						<v-btn router to="/registration">{{ $t('login.btn_new_registration') }}</v-btn>
						<v-btn router to="/reset">{{ $t('login.btn_reset_password') }}</v-btn>
					</v-row>
					<br>
				</v-card>
			</form>
		</validation-observer>
	</v-container>
</template>

<script>
import { i18n } from "@/i18n/i18n";

import {
	ValidationObserver,
	ValidationProvider,
	configure,
	extend,
	setInteractionMode,
} from "vee-validate";

import { email, required } from "vee-validate/dist/rules";
//import { sha256 } from "js-sha256";

setInteractionMode("aggressive");

configure({
	defaultMessage: (field, values) => {
		return i18n.t(`validation.${values._rule_}`, values);
	},
});

extend("email", email);
extend("required", required);

export default {
	name: "Login",
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	data: () => ({
		username: "",
		password: "",
		errors: null,
		show_login_error: false,
		show1: false,
		login_message: '-'
	}),
	methods: {
		authenticate() {
			this.$refs.observer.validate();
			if (this.errors === null) {
				this.$store.dispatch("login", {
						username: this.username,
						//password: sha256(this.password),
						password: this.password
					})
					.then(() => {
						switch(this.$store.getters.get_login_message) {
							case 'NO JSON REQUEST':
								this.login_message = 'login.alert_login_failed'
								break;
							case 'NO USERNAME':
							case 'NO PASSWORD':
							case 'WRONG CRED':
								this.login_message = 'login.alert_login_data_wrong'
								break;
							case 'NOT ACTIVE':
								this.login_message = 'login.alert_login_not_active'
								break;
							default:
								break;
						}
						if (this.$store.getters.is_authenticated) {
							this.show_login_error = false;
							this.$root.$emit("logged-in");
							this.$router.go(-1);
							// this.$router.push(
							// 	this.$router.history.current.query.redirect || "/"
							// );
							// this.$root.$emit("logged-in");
						} else {
							this.show_login_error = true;
							// bude sa ukazovat len nespravne zadane udaje alebo aj ciastkove veci umoznujuce enumeraciu registrovanych pouzivatelov a pod
							// reset validator
							// reset form
						}
					})
					.catch(() => {});
			} else {
				this.show_login_error = true;
			}
		},
	},
};
</script>

<style scoped>
</style>
